<template>
  <div :class="hasWave" class="relative">
    <ImageStrapiImage
      v-if="data?.background?.data"
      :data="data.background.data"
      class="absolute top-[-14%] z-negative h-[1400px] w-auto object-cover sm:top-[-18%] md:top-[-20%] md:h-[1800px] lg:top-[-16%] lg:h-[600px] lg:w-full" />
    <div
      class="mx-auto grid max-w-screen-xl items-center justify-center justify-items-center gap-12 px-5 lg:grid-cols-12">
      <SharedSimpleImage
        :data="data?.image"
        class="max-w-md px-2 md:col-span-6 md:px-0"
        :class="imagePosition === ImagePositionEnum.LEFT ? 'order-1' : 'order-2 lg:order-last'" />
      <div class="md:col-span-6" :class="imagePosition === ImagePositionEnum.LEFT ? 'order-2' : 'order-1'">
        <h2 class="mb-2 text-3xl leading-tight text-gray-900 md:text-4xl lg:mb-4 lg:text-5xl">
          {{ data?.title }}
        </h2>
        <h4 v-if="data?.subtitle" class="mb-6 leading-tight text-gray-900 md:text-xl lg:mb-12">
          {{ data?.subtitle }}
        </h4>
        <div class="flex flex-col gap-6" :class="{ 'mt-8 lg:mt-12': data?.subtitle }">
          <SharedListItem v-for="item in data?.listItems" :key="item.text" :data="item" />
        </div>
        <SharedButton v-if="data?.button" :data="data.button" class="mt-8 w-auto px-5 py-3" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import type { ListItemsImage } from "~/types/strapi/components/block/interfaces/ListItemsImage.ts";
import ImagePositionEnum from "~/utils/enum/ImagePosition";

const props = defineProps<{
  data: ListItemsImage;
}>();

const imagePosition = computed(() => props.data?.imagePosition ?? ImagePositionEnum.LEFT);

const hasWave = computed(() =>
  isNotNullOrUndefined(props.data?.background?.data) ? "mt-4 md:mt-8 lg:mt-12" : "my-4 md:my-8",
);
</script>

<style lang="scss" scoped></style>
